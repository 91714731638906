<template>
  <div class="card card-md">
    <div class="card-body">
      <div class="row" v-if="saveSuccess">
        <div class="col-12">
          <div class="alert alert-success">Information saved successfully.</div>
        </div>
      </div>
      <div class="row">
        <div class="markdown mb-3">
          <div class="col-12 d-flex">
            <div class="col-12 mb-1">
              <p class="h2">About {{ $auth.activeCompany.companyName }}:</p>
              <div>
                <label class="form-label mt-3">Description:</label>
                <textarea
                  :class="[
                    'form-control w-100',
                    $v.value.description.$error ? 'is-invalid' : '',
                  ]"
                  id="validateDescription"
                  ref="description"
                  v-model="$v.value.description.$model"
                  required
                  placeholder=""
                  class="description"
                />
                <div
                  class="invalid-feedback"
                  v-if="!$v.value.description.required"
                >
                  <small>Please enter a description</small>
                </div>
              </div>
            </div>
          </div>
          <save-button
            @save="validateDescription"
            @cancel="$emit('cancel')"
          ></save-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import SaveButton from "../Profile/SaveButton.vue";

export default {
  name: "Profile.Description",
  props: {
    value: Object,
    saveSuccess: Boolean,
  },
  components: {
    SaveButton,
  },
  validations: {
    value: {
      description: { required },
    },
  },
  methods: {
    validateDescription() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit("save");
      }
    },
  },
};
</script>
