<template>
  <div class="card-body">
    <div class="row">
      <p class="h2 mb-4">Clients</p>
      <div
        class="table-responsive col-md-9"
        v-if="displayClientDTOs.length > 0"
      >
        <sortable-list v-model="displayClientDTOs">
          <div class="table-striped table-bordered" slot-scope="{ items }">
            <div class="child header">
              <div class="text-left text-nowrap col-6">
                <a href="#" @click.prevent="sort(`clientName`)">Client Name</a>
              </div>
              <div class="text-center text-nowrap col-1">
                <a href="#" @click.prevent="">Edit</a>
              </div>
              <div class="text-center text-nowrap col-1">
                <a href="#" @click.prevent="">Delete</a>
              </div>
              <div class="text-center text-nowrap col-1">
                <a href="#" @click.prevent="sort(`ordinalPosition`)"
                  >Position</a
                >
              </div>
            </div>
            <sortable-item v-for="clientDTO in items" :key="clientDTO.rowid">
              <div class="child">
                <div class="text-left text-nowrap col-6 content-column">
                  <a
                    v-b-tooltip.hover
                    :title="clientDTO.clientName"
                    target="_blank"
                  >
                    {{ getDisplayClientName(clientDTO.clientName) }}
                  </a>
                </div>
                <div class="text-center text-nowrap col-1 content-column">
                  <button
                    @click.prevent="editClient(clientDTO.companyClientID)"
                    class="btn btn-block btn-primary"
                  >
                    Edit
                  </button>
                </div>
                <div class="text-center text-nowrap col-1 content-column">
                  <button
                    @click.prevent="deleteClient(clientDTO.companyClientID)"
                    class="btn btn-block btn-primary"
                  >
                    Delete
                  </button>
                </div>
                <sortable-handle>
                  <div class="text-center text-nowrap col-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-grid-dots"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="5" cy="5" r="1" />
                      <circle cx="12" cy="5" r="1" />
                      <circle cx="19" cy="5" r="1" />
                      <circle cx="5" cy="12" r="1" />
                      <circle cx="12" cy="12" r="1" />
                      <circle cx="19" cy="12" r="1" />
                      <circle cx="5" cy="19" r="1" />
                      <circle cx="12" cy="19" r="1" />
                      <circle cx="19" cy="19" r="1" />
                    </svg>
                  </div>
                </sortable-handle>
              </div>
            </sortable-item>
          </div>
        </sortable-list>
      </div>
      <div v-else>
        <span> Your company does not have any clients. </span>
      </div>
    </div>
    <div class="mt-3 mb-2" v-if="!displayAddClient">
      <div class="text-left">
        <button
          @click.prevent="saveClientOrder"
          class="btn btn-block btn-primary"
          v-if="showSaveClientOrder"
        >
          Save Client Order
        </button>
        <div :class="['d-inline', showSaveClientOrder ? 'cancel' : '']">
          <button class="btn btn-primary" @click.prevent="showClientAddEdit">
            Add Client
          </button>
        </div>
        <div class="cancel d-inline">
          <a
            href="#"
            class="btn btn-secondary"
            @click.prevent="cancelClientReorder"
            v-if="showSaveClientOrder"
          >
            Cancel
          </a>
        </div>
        <div v-if="showclientreordersuccessmsg">
          <div class="card-status-top bg-success"></div>
          <div class="mt-3">
            <div class="font-weight-bold">
              <small>Client sort order has been saved.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3 rounded" v-else>
      <div class="row mb-12">
        <label class="form-label mt-4"
          ><h3>{{ clientactionlabel }} Client:</h3></label
        >
        <div class="col-md-3 mb-3">
          <label class="form-label mt-3">Client Name: </label>
          <input
            :class="['form-control', $v.clientname.$error ? 'is-invalid' : '']"
            id="validateClientName"
            ref="clientname"
            v-model="$v.clientname.$model"
            required
          />
          <div class="invalid-feedback" v-if="!$v.clientname.required">
            <small>Please enter a client name</small>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <div>
          <a href="#" class="btn btn-primary" @click.prevent="saveClient">
            {{ clientactionlabel == "Edit" ? "Save" : "Add" }}
            Client
          </a>
          <div class="cancel d-inline">
            <a
              href="#"
              class="btn btn-secondary"
              @click.prevent="cancelAddClient"
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="clientpostSuccess">
      <div class="card-status-top bg-success"></div>
      <div class="pt-3">
        <div class="col-12 font-weight-bold">
          <small>Client has been {{ displayClientAction }}.</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import SortableItem from "../Sortable/SortableItem.vue";
import SortableHandle from "../Sortable/SortableHandle.vue";
import SortableList from "../Sortable/SortableList.vue";
import ProfileService from "../../services/ProfileService";
import TrackingService from "../../services/TrackingService";
import { isBefore } from "date-fns"; //isBefore  //parseISO format isBefore   format(parseISO(tradeshowDTO.tradeShowEndDate), "MM/dd/yyyy")

export default {
  name: "clients",
  components: {
    SortableItem,
    SortableHandle,
    SortableList,
  },
  data: () => {
    return {
      addclientvisible: false,
      clientorderchanged: false,
      clientactionlabel: "",
      showclientreordersuccessmsg: false,
      clientname: "",
      existingClients: [],
      clientDTOs: [],
      formErrors: {},
      clientordinalposition: 0,
      sortdir: "asc",
      sortcol: "ordinalPosition",
      clientpostSuccess: false,
      filter: {
        selected_categoryids: [],
      },
    };
  },
  validations: { clientname: { required } },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.sortcol) {
        this.sortdir = this.sortdir === "asc" ? "desc" : "asc";
      }
      this.sortcol = s;
    },
    clearForm() {
      // clear our form
      this.clientpostSuccess = false;
      this.postResult = [];
      this.addclientvisible = false;
      this.clientorderchanged = false;
      this.showclientreordersuccessmsg = false;
      this.clientname = "";
      this.clientordinalposition = 0;
      this.companyclientid = 0;

      this.$v.$reset();
    },
    cancelAddClient() {
      this.clearForm();
    },
    getDisplayClientName(client) {
      if (client && client.length > 60) {
        client = client.substring(0, 60) + "...";
      }

      return client;
    },
    editClient(companyclientid) {
      // update
      this.clientactionlabel = "Edit";

      // set edit companytradeshowid
      this.companyclientid = companyclientid;

      // set client name
      this.clientname = this.existingClients.filter(
        (a) => a.companyClientID == companyclientid
      )[0].clientName;

      // set client ordinalposition
      this.clientordinalposition = this.existingClients.filter(
        (a) => a.companyClientID == companyclientid
      )[0].ordinalPosition;

      // show client add edit
      this.addclientvisible = true;
    },
    async saveClientOrder() {
      // clear form
      this.clearForm();

      let company = this.$auth.activeCompany;

      let ClientTradeShowSaveOrderDTO = {
        company: company,
        clients: this.clientDTOs,
      };

      TrackingService.track("rearrange client list");

      try {
        let postResult = await ProfileService.saveClientOrder(
          ClientTradeShowSaveOrderDTO
        );
        if (postResult.success) {
          this.showclientreordersuccessmsg = true;
          this.clientorderchanged = false;
          this.clientpostSuccess = false;
          await this.getClients();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getClients() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let clientDTOs = await ProfileService.getClients(companyFilter);

      // iterate through each row (start at 1)
      for (var i = 0; i <= clientDTOs.length - 1; i++) {
        clientDTOs[i].rowid = i + 1;
      }

      // sort by client ordinal position
      this.clientDTOs = clientDTOs.sort((a) => a.ordinalPosition);

      //save existing client data
      this.existingClients = clientDTOs;
    },
    showClientAddEdit() {
      // clear values
      this.clearForm();

      // show client add edit
      this.addclientvisible = true;

      // update
      this.clientactionlabel = "Add";
    },
    async deleteClient(companyclientid) {
      let company = this.$auth.activeCompany;

      let ClientDeleteDTO = {
        company: company,
        companyclientid: companyclientid,
      };
      if (confirm("Are you sure that you want to delete this client?")) {
        TrackingService.track("delete client", {
          detail: this.clientName,
        });

        try {
          let postResult = await ProfileService.deleteClient(ClientDeleteDTO);
          if (postResult.success) {
            this.companyclientid = "";
            this.clientname = "";
            await this.getClients();
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async cancelClientReorder() {
      this.clientorderchanged = false;
      await this.getClients();
    },
    async saveClient() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        // get current companyif
        let companyid = this.$auth.activeCompany.companyId;

        // no errors, build client DTO to post (same DTO for "Add" and "Save")
        let ClientDTO = {
          companyid: companyid,
          companyclientid: this.companyclientid,
          clientname: this.clientname,
          ordinalposition: this.clientordinalposition,
          updateonly: this.companyclientid > 0 ? true : false,
        };

        if (ClientDTO.updateonly) {
          TrackingService.track("update client", {
            detail: this.clientName,
          });
        } else {
          TrackingService.track("add new client", {
            detail: this.clientName,
          });
        }

        try {
          let postResult = await ProfileService.saveClient(ClientDTO);
          if (postResult.success) {
            this.clientpostSuccess = true;
            this.addclientvisible = false;
            await this.$auth.identify();
            await this.getClients();
          } else {
            this.clientpostSuccess = false;
          }
        } catch (e) {
          console.log(e);
        }
      }

      this.$v.$reset();
    },
  },
  computed: {
    showSaveClientOrder() {
      return this.clientorderchanged;
    },
    displayClientDTOs: {
      get() {
        return this.clientDTOs
          .filter((n) => {
            if (this.filter.selected_categoryids.length == 0) return true;
            return (
              this.filter.selected_categoryids.indexOf(n.categoryId) >= 0 ||
              this.filter.selected_categoryids.indexOf(n.categoryId) == -1
            );
          })
          .sort((a, b) => {
            let modifier = 1;
            if (this.sortdir === "desc") modifier = -1;

            if (this.sortcol != "createDate" && this.sortcol != "createDate") {
              if (a[this.sortcol] < b[this.sortcol]) return -1 * modifier;
              if (a[this.sortcol] > b[this.sortcol]) return 1 * modifier;
            } else {
              if (
                isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol]))
              )
                return -1 * modifier;
              if (
                !isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol]))
              )
                return 1 * modifier;
            }
          });
      },
      set(value) {
        this.clientDTOs = value;
        this.clientorderchanged = true;
        this.showclientreordersuccessmsg = false;
      },
    },
    displayAddClient() {
      return this.addclientvisible;
    },
    displayClientAction() {
      return this.clientactionlabel == "Edit"
        ? "saved"
        : this.clientactionlabel == "Add"
        ? "added"
        : "deleted";
    },
  },
  async mounted() {
    // get Clients
    this.getClients();
  },
};
</script>
