<template>
  <div class="card">
    <div class="card-body">
      <slot></slot>

      <router-link to="/upgrade" class="btn btn-outline-primary">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-bolt"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <polyline points="13 3 13 10 19 10 11 21 11 14 5 14 13 3"></polyline>
        </svg>

        Upgrade Now
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "upgrade.upgrade-box",
  data() {
    return {};
  },
};
</script>
