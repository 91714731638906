<template>
  <div class="card card-md">
    <div class="card-body" v-if="canAccess('social-links', $auth.highestPlan)">
      <div class="row" v-if="saveSuccess">
        <div class="col-12">
          <div class="alert alert-success">Information saved successfully.</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3">
          <p class="h2">Social Media Links</p>
          <div class="col-md-6">
            <label class="form-label mt-3">Facebook Page:</label>
            <div class="input-group">
              <span class="input-group-text"> https://www.facebook.com/ </span>
              <input
                type="text"
                class="form-control"
                placeholder="screenname"
                autocomplete="off"
                v-model="value.facebookurl"
              />
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label mt-3">Twitter Page:</label>
            <div class="input-group">
              <span class="input-group-text"> https://twitter.com/ </span>
              <input
                type="text"
                class="form-control"
                placeholder="screenname"
                autocomplete="off"
                v-model="value.twitterurl"
              />
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label mt-3">LinkedIn Page:</label>
            <div class="input-group">
              <span class="input-group-text"> https://www.linkedin.com/ </span>
              <input
                type="text"
                class="form-control"
                placeholder="screenname"
                autocomplete="off"
                v-model="value.linkedinurl"
              />
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label mt-3">Instagram Page:</label>
            <div class="input-group">
              <span class="input-group-text"> https://www.instagram.com/ </span>
              <input
                type="text"
                class="form-control"
                placeholder="screenname"
                autocomplete="off"
                v-model="value.instagramurl"
              />
            </div>
          </div>
          <div class="col-md-6">
            <label class="form-label mt-3">YouTube Page:</label>
            <div class="input-group">
              <span class="input-group-text"> https://www.youtube.com/ </span>
              <input
                type="text"
                class="form-control"
                placeholder="screenname"
                autocomplete="off"
                v-model="value.youtubeurl"
              />
            </div>
          </div>
          <save-button
            @save="$emit('save')"
            @cancel="$emit('cancel')"
          ></save-button>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <p class="h2">Social Media Links</p>
      <upgrade-box>
        <p>
          <strong>
            Upgrade to feature your company's social media links on your
            profile.
          </strong>
        </p>
      </upgrade-box>
    </div>
  </div>
</template>

<script>
import SaveButton from "./SaveButton.vue";
import UpgradeBox from "../Upgrade/UpgradeBox.vue";
import UpsellService from "../../services/UpsellService";

export default {
  name: "profile.social-media",
  props: {
    value: Object,
    saveSuccess: Boolean,
  },
  components: {
    SaveButton,
    UpgradeBox,
  },
  methods: {
    canAccess(feature, plan) {
      return UpsellService.planIncludes(feature, plan);
    },
  },
};
</script>
