<script>
import { Sortable } from "@shopify/draggable";

function move(items, oldIndex, newIndex) {
  const itemRemovedArray = [
    ...items.slice(0, oldIndex),
    ...items.slice(oldIndex + 1, items.length),
  ];

  var results = [
    ...itemRemovedArray.slice(0, newIndex),
    items[oldIndex],
    ...itemRemovedArray.slice(newIndex, itemRemovedArray.length),
  ];

  // var oldItems = items[oldIndex];
  // var newItems = items[newIndex];

  // items[oldIndex] = newItems;
  // items[newIndex] = oldItems;

  // for(var i=0; i <= items.length-1; i++)
  // {
  //   items[i].ordinalPosition = i;
  // };

  // var newitems = items.sort((a) => a.ordinalPosition);

  // return newitems;

  for (var i = 0; i <= results.length - 1; i++) {
    results[i].ordinalPosition = i;
  }

  results = results.sort((a) => a.ordinalPosition);

  return results;
}

export default {
  props: {
    value: {
      required: true,
    },
    itemClass: {
      default: "sortable-item",
    },
    handleClass: {
      default: "sortable-handle",
    },
  },
  provide() {
    return {
      itemClass: this.itemClass,
      handleClass: this.handleClass,
    };
  },
  render() {
    return this.$scopedSlots.default({
      items: this.value,
    });
  },
  mounted() {
    const sortable = new Sortable(this.$el, {
      draggable: `.${this.itemClass}`,
      handle: `.${this.handleClass}`,
      mirror: {
        constrainDimensions: true,
      },
    }).on("sortable:stop", ({ oldIndex, newIndex }) => {
      this.$emit("input", move(this.value, oldIndex, newIndex));
    });

    this.$on("hook:destroyed", () => {
      sortable.destroy();
    });
  },
};
</script>
