<template>
  <div class="mt-4">
    <div>
      <a href="#" class="btn btn-primary" @click.prevent="$emit('save')">
        Save Profile
      </a>

      <div class="cancel d-inline">
        <a href="#" class="btn btn-secondary" @click.prevent="$emit('cancel')">
          Reset
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile.save-button",
};
</script>
