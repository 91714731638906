var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"sm","id":(_vm.uniqueId + "-advanced-url-modal"),"hide-header":true,"hide-footer":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var close = ref.close;
return [_c('div',{staticClass:"modal-status bg-info"}),_c('button',{staticClass:"btn-close",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return close()}}}),_c('h3',[_vm._v("Edit URL")]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-check form-switch"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.scheme == 'https:'},on:{"change":function($event){_vm.scheme == 'https:' ? (_vm.scheme = 'http:') : (_vm.scheme = 'https:')}}}),_c('span',{staticClass:"form-check-label"},[_vm._v("Secure URL (HTTPS)")])])]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":(_vm.uniqueId + "-advanced-modal-host")}},[_vm._v(" Base URL ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.host),expression:"host"}],staticClass:"form-control",attrs:{"type":"text","id":(_vm.uniqueId + "-advanced-modal-host")},domProps:{"value":(_vm.host)},on:{"input":function($event){if($event.target.composing){ return; }_vm.host=$event.target.value}}})]),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label",attrs:{"for":(_vm.uniqueId + "-advanced-modal-path")}},[_vm._v(" Path ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.path),expression:"path"}],staticClass:"form-control",attrs:{"type":"text","id":(_vm.uniqueId + "-advanced-modal-path")},domProps:{"value":(_vm.path)},on:{"input":function($event){if($event.target.composing){ return; }_vm.path=$event.target.value}}})]),_c('parameter-group',{attrs:{"groupName":"Marketing Parameters","uniqueId":(_vm.uniqueId + "-marketing-params"),"editable":true,"matchParameters":[
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content' ]},model:{value:(_vm.parameters),callback:function ($$v) {_vm.parameters=$$v},expression:"parameters"}}),_c('parameter-group',{attrs:{"groupName":"Custom Parameters","uniqueId":(_vm.uniqueId + "-custom-params"),"matchParameters":[],"excludeParameters":[
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'ntls' ],"editable":true},model:{value:(_vm.parameters),callback:function ($$v) {_vm.parameters=$$v},expression:"parameters"}}),_c('parameter-group',{attrs:{"groupName":"System Parameters","uniqueId":(_vm.uniqueId + "-system-params"),"editable":false,"matchParameters":['ntls']},model:{value:(_vm.parameters),callback:function ($$v) {_vm.parameters=$$v},expression:"parameters"}}),_c('div',{staticClass:"mb-3"},[_c('label',{staticClass:"form-label"},[_vm._v(" Final URL ( "),_c('a',{attrs:{"href":_vm.domain,"target":"_blank"}},[_vm._v("Open preview in a new tab")]),_vm._v(" ) ")]),_c('input',{staticClass:"form-control disabled",attrs:{"type":"text","disabled":""},domProps:{"value":_vm.domain}})]),_c('div',{staticClass:"mb-3"},[_c('button',{staticClass:"btn btn-primary",on:{"click":close}},[_vm._v("Close Editor")])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }