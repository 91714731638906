<template>
  <div
    :class="
      focused
        ? ['border', 'rounded', invalid || hasError ? 'border-danger' : '']
        : []
    "
  >
    <input
      type="text"
      :class="['form-control', invalid || hasError ? 'is-invalid' : '']"
      @blur="calculateErrors"
      @focus="focus"
      v-model="domain"
      :id="uid"
    />
    <div class="invalid-feedback d-inline" v-if="invalid">
      <small>{{ feedback }}</small>
    </div>
    <div class="invalid-feedback d-inline" v-else-if="hasError">
      <small>{{ error }}</small>
    </div>
    <div class="d-flex mb-1">
      <a
        class="ms-1 mt-1 small d-flex align-items-center"
        :href="domain"
        target="_blank"
      >
        Open Test Link

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class=""
          width="18"
          height="18"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5"
          ></path>
          <path d="M10 14l10 -10"></path>
          <path d="M15 4l5 0l0 5"></path>
        </svg>
      </a>

      <a
        class="ms-auto mt-1 me-1 small"
        href="#"
        @click.prevent="$bvModal.show(`${uid}-advanced-url-modal`)"
      >
        Advanced URL Editor
      </a>

      <advanced-url-modal v-model="domain" :uniqueId="uid"></advanced-url-modal>
    </div>
  </div>
</template>

<script>
import AdvancedUrlModal from "./AdvancedUrl/AdvancedUrlModal.vue";
export default {
  components: { AdvancedUrlModal },
  name: "forms.advanced-url",
  props: {
    uid: String,
    value: String,
    feedback: String,
    required: Boolean,
    invalid: Boolean,
  },
  data() {
    return {
      domain: this.value,
      error: "",
      focused: false,
    };
  },
  computed: {
    hasError() {
      if (!this.required && this.domain == "") {
        return false;
      }

      try {
        new URL(this.domain);
        return false;
      } catch (e) {
        return true;
      }
    },
  },
  watch: {
    domain() {
      this.$emit("input", this.domain);
    },
  },
  methods: {
    focus() {
      this.focused = true;
    },
    calculateErrors() {
      this.$nextTick(() => {
        this.focused = false;
      });
      this.domain = this.domain.trim();
      let error = "";
      try {
        new URL(this.domain);
      } catch (e) {
        if (
          !this.domain.startsWith("http://") &&
          !this.domain.startsWith("https://")
        ) {
          this.domain = `https://${this.domain}`;
          this.calculateErrors();
          return;
        } else {
          error = "Please enter a valid domain.";
        }
      }
      this.error = error;
    },
  },
};
</script>
