<template>
  <div class="card-body">
    <div class="row">
      <p class="h2 mb-4">Events</p>
      <div class="table-responsive" v-if="displayTradeShowDTOs.length > 0">
        <sortable-list v-model="displayTradeShowDTOs">
          <div class="table-striped table-bordered" slot-scope="{ items }">
            <div class="child header">
              <div class="text-left text-nowrap col-3">
                <a href="#" @click.prevent="sort(`tradeShowName`)"
                  >Event Name</a
                >
              </div>
              <div class="text-center text-nowrap col-1">
                <a href="#" @click.prevent="sort(`boothNumber`)"
                  >Event Time or Booth #</a
                >
              </div>
              <div class="text-left text-nowrap col-1">
                <a href="#" @click.prevent="sort(`tradeShowStartDate`)"
                  >Start Date</a
                >
              </div>
              <div class="text-left text-nowrap col-1">
                <a href="#" @click.prevent="sort(`tradeShowEndDate`)"
                  >End Date</a
                >
              </div>
              <div class="text-center text-nowrap col-1">
                <a href="#" @click.prevent="">Edit</a>
              </div>
              <div class="text-center text-nowrap col-1">
                <a href="#" @click.prevent="">Delete</a>
              </div>
              <div class="text-center text-nowrap col-1">
                <a href="#" @click.prevent="sort(`ordinalPosition`)"
                  >Position</a
                >
              </div>
            </div>
            <sortable-item
              v-for="tradeshowDTO in items"
              :key="tradeshowDTO.rowid"
            >
              <div class="child">
                <div class="text-left text-nowrap col-3 content-column">
                  {{ tradeshowDTO.tradeShowName }}
                </div>
                <div class="text-center text-nowrap col-1 content-column">
                  {{ tradeshowDTO.boothNumber }}
                </div>
                <div class="text-left text-nowrap col-1 content-column">
                  {{ formatTradeShowDate(tradeshowDTO.tradeShowStartDate) }}
                </div>
                <div class="text-left text-nowrap col-1 content-column">
                  {{ formatTradeShowDate(tradeshowDTO.tradeShowEndDate) }}
                </div>
                <div class="text-center text-nowrap col-1 content-column">
                  <button
                    @click.prevent="
                      editTradeShow(tradeshowDTO.companyTradeShowID)
                    "
                    class="btn btn-block btn-primary"
                  >
                    Edit
                  </button>
                </div>
                <div class="text-center text-nowrap col-1 content-column">
                  <button
                    @click.prevent="
                      deleteTradeShow(tradeshowDTO.companyTradeShowID)
                    "
                    class="btn btn-block btn-primary"
                  >
                    Delete
                  </button>
                </div>
                <sortable-handle>
                  <div class="text-center text-nowrap col-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-grid-dots"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <circle cx="5" cy="5" r="1" />
                      <circle cx="12" cy="5" r="1" />
                      <circle cx="19" cy="5" r="1" />
                      <circle cx="5" cy="12" r="1" />
                      <circle cx="12" cy="12" r="1" />
                      <circle cx="19" cy="12" r="1" />
                      <circle cx="5" cy="19" r="1" />
                      <circle cx="12" cy="19" r="1" />
                      <circle cx="19" cy="19" r="1" />
                    </svg>
                  </div>
                </sortable-handle>
              </div>
            </sortable-item>
          </div>
        </sortable-list>
      </div>
      <div v-else>
        <span> Your company does not have any events. </span>
      </div>
    </div>
    <div class="mt-3 mb-2" v-if="!displayAddTradeShow">
      <div class="text-left">
        <button
          @click.prevent="saveTradeShowOrder"
          class="btn btn-block btn-primary"
          v-if="showSaveTradeShowOrder"
        >
          Save Event Order
        </button>
        <div :class="['d-inline', showSaveTradeShowOrder ? 'cancel' : '']">
          <button class="btn btn-primary" @click.prevent="showTradeShowAddEdit">
            Add Event
          </button>
        </div>
        <div class="cancel d-inline">
          <a
            href="#"
            class="btn btn-secondary"
            @click.prevent="cancelReorder"
            v-if="showSaveTradeShowOrder"
          >
            Cancel
          </a>
        </div>
        <div v-if="showreordersuccessmsg">
          <div class="card-status-top bg-success"></div>
          <div class="mt-3">
            <div class="font-weight-bold">
              <small>Event sort order has been saved.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mb-3 rounded" v-else>
      <div class="row mb-12">
        <label class="form-label mt-4"
          ><h3>{{ tradeshowactionlabel }} Event:</h3></label
        >
        <div class="col-md-3 mb-3">
          <label class="form-label mt-3">Event Name:</label>
          <input
            :class="[
              'form-control',
              $v.tradeshowname.$error ? 'is-invalid' : '',
            ]"
            id="validateTradeShowName"
            ref="tradeshowname"
            v-model="$v.tradeshowname.$model"
            required
          />
          <div class="invalid-feedback" v-if="!$v.tradeshowname.required">
            <small>Please enter a trade show name</small>
          </div>
        </div>
        <div class="col-md-3">
          <label class="form-label mt-3">Event Time or Booth #:</label>
          <input
            :class="['form-control', $v.boothnumber.$error ? 'is-invalid' : '']"
            id="validateBoothNumber"
            ref="boothnumber"
            v-model="$v.boothnumber.$model"
            required
          />
          <div class="invalid-feedback" v-if="!$v.boothnumber.required">
            <small>Please enter a trade show booth number</small>
          </div>
        </div>
        <div class="col-md-3">
          <label class="form-label mt-3">Event Start Date:</label>
          <date-select
            :label="``"
            :setdate="tradeshowstartdate"
            :errormessage="tradeshowstartdateerrormessage"
            id="validateTradeShowStartDate"
            ref="tradeshowstartdate"
            v-on:update:selected="updateSelectedStartDate"
          ></date-select>
        </div>
        <div class="col-md-3">
          <label class="form-label mt-3">Event End Date:</label>
          <date-select
            :label="``"
            :setdate="tradeshowenddate"
            :errormessage="tradeshowenddateerrormessage"
            id="validateTradeShowEndDate"
            ref="tradeshowenddate"
            v-on:update:selected="updateSelectedEndDate"
          ></date-select>
        </div>
      </div>
      <div class="mt-2">
        <div>
          <a href="#" class="btn btn-primary" @click.prevent="saveTradeShow">
            {{ tradeshowactionlabel == "Edit" ? "Save" : "Add" }}
            Event
          </a>
          <div class="cancel d-inline">
            <a
              href="#"
              class="btn btn-secondary"
              @click.prevent="cancelAddTradeShow"
            >
              Cancel
            </a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="tradeshowpostSuccess">
      <div class="card-status-top bg-success"></div>
      <div class="pt-3">
        <div class="col-12 font-weight-bold">
          <small>Event has been {{ displayTradeShowAction }}.</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ProfileService from "../../services/ProfileService";
import TrackingService from "../../services/TrackingService";
import SortableHandle from "../Sortable/SortableHandle.vue";
import SortableItem from "../Sortable/SortableItem.vue";
import SortableList from "../Sortable/SortableList.vue";
import DateSelect from "./Controls/DateSelect.vue";
import { isBefore, parseISO, format } from "date-fns"; //isBefore  //parseISO format isBefore   format(parseISO(tradeshowDTO.tradeShowEndDate), "MM/dd/yyyy")

export default {
  name: "trade-shows",
  components: {
    SortableHandle,
    SortableItem,
    SortableList,
    DateSelect,
  },
  data: () => {
    return {
      sortdir: "asc",
      sortcol: "ordinalPosition",
      tradeShowDTOs: [],
      tradeshowDTOs: [],
      existingTradeShows: [],
      tradeshowstartdateerrormessage: "Please enter a trade show startdate",
      tradeshowenddateerrormessage: "Please enter a trade show enddate",
      tradeshowactionlabel: "Add",
      companytradeshowid: 0,
      tradeshowname: "",
      boothnumber: "",
      tradeshowstartdate: "",
      tradeshowenddate: "",
      tradeshowordinalposition: 0,
      tradeshoworderchanged: false,
      showreordersuccessmsg: false,
      formErrors: {},
      tradeshowpostSuccess: false,
      addtradeshowvisible: false,
      filter: {
        selected_categoryids: [],
        selected_startdate: "",
        selected_enddate: "",
      },
    };
  },
  validations: {
    tradeshowname: { required },
    boothnumber: { required },
    tradeshowstartdate: { required },
    tradeshowenddate: { required },
  },
  methods: {
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.sortcol) {
        this.sortdir = this.sortdir === "asc" ? "desc" : "asc";
      }
      this.sortcol = s;
    },
    clearForm() {
      // clear our form
      this.tradeshowpostSuccess = false;
      this.postResult = [];
      this.addtradeshowvisible = false;
      this.tradeshoworderchanged = false;
      this.showreordersuccessmsg = false;

      this.tradeshowname = "";
      this.boothnumber = "";
      this.tradeshowstartdate = "";
      this.tradeshowenddate = "";
      this.tradeshowordinalposition = 0;
      this.companytradeshowid = 0;

      this.tradeshowactionlabel = "Add";

      this.$v.$reset();
    },
    cancelAddTradeShow() {
      this.clearForm();
    },
    formatTradeShowDate(tradeshowdate) {
      return format(parseISO(tradeshowdate), "MM/dd/yyyy");
    },
    updateSelectedStartDate(selected) {
      this.$set(this.filter, "selected_startdate", selected);
      this.tradeshowstartdate = selected;
    },
    updateSelectedEndDate(selected) {
      this.$set(this.filter, "selected_enddate", selected);
      this.tradeshowenddate = selected;
    },
    showTradeShowAddEdit() {
      // clear our form
      this.clearForm();

      // set flag
      this.addtradeshowvisible = true;

      // update
      this.tradeshowactionlabel = "Add";

      // scroll to  bottom of page
      this.scrollToBottom("TradeShows");
    },
    async cancelReorder() {
      this.tradeshoworderchanged = false;
      await this.getTradeShows();
    },
    editTradeShow(companytradeshowid) {
      // clear values
      this.clearForm();

      // update
      this.tradeshowactionlabel = "Edit";

      // set edit companytradeshowid
      this.companytradeshowid = companytradeshowid;

      // set trade show name
      this.tradeshowname = this.existingTradeShows.filter(
        (a) => a.companyTradeShowID == companytradeshowid
      )[0].tradeShowName;

      // set booth number
      this.boothnumber = this.existingTradeShows.filter(
        (a) => a.companyTradeShowID == companytradeshowid
      )[0].boothNumber;

      // set trade show start date
      this.tradeshowstartdate = this.existingTradeShows.filter(
        (a) => a.companyTradeShowID == companytradeshowid
      )[0].tradeShowStartDate;

      // set trade show end date
      this.tradeshowenddate = this.existingTradeShows.filter(
        (a) => a.companyTradeShowID == companytradeshowid
      )[0].tradeShowEndDate;

      // set trade show ordinalposition
      this.tradeshowordinalposition = this.existingTradeShows.filter(
        (a) => a.companyTradeShowID == companytradeshowid
      )[0].ordinalPosition;

      // show trade show add edit
      this.addtradeshowvisible = true;

      // scroll to  bottom of page
      this.scrollToBottom("TradeShows");
    },
    scrollToBottom(anchorname) {
      // get reference to tradeshow div
      const el = this.$el.querySelector("#" + anchorname);

      if (el) {
        // force smooth window scroll to bottom
        setTimeout(function () {
          el.scrollIntoView({ behavior: "smooth" });
        }, 40);
      }
    },
    async deleteTradeShow(companytradeshowid) {
      let company = this.$auth.activeCompany;

      TrackingService.track("delete event", {
        detail: this.tradeshowname,
      });

      let TradeShowDeleteDTO = {
        company: company,
        companytradeshowid: companytradeshowid,
      };

      // clear form
      this.clearForm();

      if (confirm("Are you sure that you want to delete this event?")) {
        try {
          let postResult = await ProfileService.deleteTradeShow(
            TradeShowDeleteDTO
          );
          if (postResult.success) {
            this.companytradeshowid = "";
            this.tradeshowname = "";
            this.boothnumber = "";
            this.tradeshowstartdate = "";
            this.tradeshowenddate = "";
            await this.getTradeShows();
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    async getTradeShows() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let tradeShowDTOs = await ProfileService.getTradeShows(companyFilter);

      // iterate through each row (start at 1)
      for (var i = 0; i <= tradeShowDTOs.length - 1; i++) {
        tradeShowDTOs[i].rowid = i + 1;
      }

      // sort by trade show ordinal position
      this.tradeShowDTOs = tradeShowDTOs.sort((a) => a.ordinalPosition);

      //save existing trade show data
      this.existingTradeShows = tradeShowDTOs;
    },
    async saveTradeShow() {
      this.$v.$touch();

      if (!this.$v.invalid) {
        // get current companyif
        let companyid = this.$auth.activeCompany.companyId;

        // no errors, build tradeshow DTO to post (same DTO for "Add" and "Save")
        let TradeShowDTO = {
          companyid: companyid,
          companytradeshowid: this.companytradeshowid,
          tradeshowname: this.tradeshowname,
          boothnumber: this.boothnumber,
          tradeshowstartdate:
            this.filter.selected_startdate.length > 0
              ? parseISO(this.filter.selected_startdate)
              : parseISO(this.tradeshowstartdate),
          tradeshowenddate:
            this.filter.selected_enddate.length > 0
              ? parseISO(this.filter.selected_enddate)
              : parseISO(this.tradeshowenddate),
          ordinalposition: this.tradeshowordinalposition,
          updateonly: this.companytradeshowid > 0 ? true : false,
        };

        if (TradeShowDTO.updateonly) {
          TrackingService.track("update event", {
            detail: this.tradeshowname,
          });
        } else {
          TrackingService.track("add event", {
            detail: this.tradeshowname,
          });
        }

        try {
          let postResult = await ProfileService.saveTradeShow(TradeShowDTO);
          if (postResult.success) {
            this.tradeshowpostSuccess = true;
            this.addtradeshowvisible = false;
            await this.$auth.identify();
            await this.getTradeShows();
          } else {
            this.tradeshowpostSuccess = false;
          }
        } catch (e) {
          console.log(e);
        }
      }

      this.$v.$reset();
    },
    async saveTradeShowOrder() {
      // clear form
      this.clearForm();

      let company = this.$auth.activeCompany;

      let TradeShowSaveOrderDTO = {
        company: company,
        tradeshows: this.tradeShowDTOs,
      };

      try {
        let postResult = await ProfileService.saveTradeShowOrder(
          TradeShowSaveOrderDTO
        );
        if (postResult.success) {
          this.showreordersuccessmsg = true;
          this.tradeshoworderchanged = false;
          this.tradeshowpostSuccess = false;
          await this.getTradeShows();
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    filteredTradeShowDTOs() {
      return this.tradeShowDTOs
        .filter((n) => {
          if (this.filter.selected_categoryids.length == 0) return true;
          return (
            this.filter.selected_categoryids.indexOf(n.categoryId) >= 0 ||
            this.filter.selected_categoryids.indexOf(n.categoryId) == -1
          );
        })
        .sort((a, b) => {
          let modifier = 1;
          if (this.sortdir === "desc") modifier = -1;

          if (
            this.sortcol != "tradeShowStartDate" &&
            this.sortcol != "tradeShowEndDate"
          ) {
            if (a[this.sortcol] < b[this.sortcol]) return -1 * modifier;
            if (a[this.sortcol] > b[this.sortcol]) return 1 * modifier;
          } else {
            if (isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol])))
              return -1 * modifier;
            if (!isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol])))
              return 1 * modifier;
          }
        });
    },
    showSaveTradeShowOrder() {
      return this.tradeshoworderchanged;
    },
    displayTradeShowDTOs: {
      get() {
        return this.tradeShowDTOs
          .filter((n) => {
            if (this.filter.selected_categoryids.length == 0) return true;
            return (
              this.filter.selected_categoryids.indexOf(n.categoryId) >= 0 ||
              this.filter.selected_categoryids.indexOf(n.categoryId) == -1
            );
          })
          .sort((a, b) => {
            let modifier = 1;
            if (this.sortdir === "desc") modifier = -1;

            if (
              this.sortcol != "tradeShowStartDate" &&
              this.sortcol != "tradeShowEndDate"
            ) {
              if (a[this.sortcol] < b[this.sortcol]) return -1 * modifier;
              if (a[this.sortcol] > b[this.sortcol]) return 1 * modifier;
            } else {
              if (
                isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol]))
              )
                return -1 * modifier;
              if (
                !isBefore(new Date(a[this.sortcol]), new Date(b[this.sortcol]))
              )
                return 1 * modifier;
            }
          });
      },
      set(value) {
        this.tradeShowDTOs = value;
        this.tradeshoworderchanged = true;
        this.showreordersuccessmsg = false;
      },
    },
    displayAddTradeShow() {
      return this.addtradeshowvisible;
    },
    displayTradeShowAction() {
      return this.tradeshowactionlabel == "Edit"
        ? "saved"
        : this.tradeshowactionlabel == "Add"
        ? "added"
        : "deleted";
    },
  },
  async mounted() {
    // get TradeShows
    this.getTradeShows();
  },
};
</script>
