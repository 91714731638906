<template>
  <div class="" v-if="sites.length > 0">
    <label class="form-label">
      Company Listings:
      <span class="text-muted">
        (Access to profile features is based on your subscription level)
      </span>
    </label>

    <site-listing
      v-for="site in sites"
      :key="`site-${site.siteID}`"
      :site="site"
    >
    </site-listing>
  </div>
</template>

<script>
import SiteListing from "./SiteListing.vue";
export default {
  name: "profile.listings",
  components: {
    SiteListing,
  },
  computed: {
    sites() {
      return [...this.$auth.activeCompany.sites].sort(
        (a, b) => a.siteRank - b.siteRank
      );
    },
  },
};
</script>
