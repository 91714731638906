<template>
  <div class="home page-wrapper">
    <div class="container-xl">
      <!-- Page title -->
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <!-- Page pre-title -->
            <div class="page-pretitle">
              {{ $auth.activeCompany.companyName }}
            </div>
            <h2 class="page-title">Company Profile</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-deck row-cards" v-if="loaded">
          <ProfileInfo
            v-model="profile.profileInfo"
            :saveSuccess="saveSuccess"
            @save="saveProfile"
            @cancel="cancelEdit"
          ></ProfileInfo>
          <SocialMedia
            v-model="profile.socialMediaLinks"
            :saveSuccess="saveSuccess"
            @save="saveProfile"
            @cancel="cancelEdit"
          >
          </SocialMedia>
          <Description
            v-model="profile.description"
            :saveSuccess="saveSuccess"
            @save="saveProfile"
            @cancel="cancelEdit"
          >
          </Description>
          <div
            class="card card-md"
            id="TradeShows"
            v-if="canAccess('tradeshows', $auth.highestPlan)"
          >
            <TradeShows></TradeShows>
          </div>
          <div v-else class="card">
            <div class="card-body">
              <h2>Events</h2>
              <upgrade-box>
                <p>
                  <strong>
                    Upgrade to showcase upcoming events on your profile.
                  </strong>
                </p>
              </upgrade-box>
            </div>
          </div>
          <div
            class="card card-md"
            id="Clients"
            v-if="canAccess('tradeshows', $auth.highestPlan)"
          >
            <Clients></Clients>
          </div>
          <div v-else class="card">
            <div class="card-body">
              <h2>Clients</h2>
              <upgrade-box>
                <p>
                  <strong>
                    Upgrade to showcase clients your company works with on your
                    profile.
                  </strong>
                </p>
              </upgrade-box>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.table .text-right {
  text-align: right;
}
.table .text-left {
  text-align: left;
}
.table .text-center {
  text-align: center;
}
.table-responsive {
  margin-bottom: 0px;
}
.alert {
  padding-top: 0.4375rem;
  padding-right: 1rem;
  padding-bottom: 0.4375rem;
  padding-left: 1rem;
}
.description {
  width: 470px;
  height: 200px;
  resize: none;
}
.logomsg {
  padding-left: 10px;
  display: inline;
}
.header {
  padding: 5px;
  background: #f4f6fa;
  font-size: 0.625rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 1.6;
  color: #656d77;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding: 0.5rem 0.5rem;
  border-bottom-width: 1px;
}
.child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  vertical-align: middle;
  border-style: solid;
  border-width: 0;
  vertical-align: middle;
  padding: 0.5rem 1rem 0.5rem;
  border-color: rgba(101, 109, 119, 0.16);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--tblr-table-accent-bg);
  padding-left: 1rem;
}
.child:nth-child(odd) {
  background: #f4f6fa;
}
.child:not(:first-child) .content-column {
  border-top: 0px;
}
.image-column {
  border: 0px;
}

.linkDisabled {
  font-family: Verdana;
  font-size: 12px;
  text-decoration: none;
  color: #d66b01;
  cursor: help;
}

.leaveroomfortooltip {
  width: calc(100% - 10px) !important;
}

.p-right {
  padding-right: 0px !important;
}

.logoalt {
  padding-left: 4px;
}

.checkboxes label {
  display: inline-block !important;
  padding-right: 10px !important;
  white-space: nowrap !important;
}

.checkboxes input {
  vertical-align: middle !important;
}

.checkboxes label span {
  vertical-align: middle !important;
}

.cancel {
  margin-left: 15px;
}
</style>

<script>
import UpsellService from "../services/UpsellService";
import TrackingService from "../services/TrackingService";
import ProfileService from "../services/ProfileService";
import Description from "../components/Profile/Description.vue";
import SocialMedia from "../components/Profile/SocialMedia.vue";
import ProfileInfo from "../components/Profile/ProfileInfo.vue";
import Clients from "../components/Profile/Clients.vue";
import TradeShows from "../components/Profile/TradeShows.vue";
import UpgradeBox from "../components/Upgrade/UpgradeBox.vue";

export default {
  name: "Profile",
  components: {
    ProfileInfo,
    UpgradeBox,
    Description,
    SocialMedia,
    Clients,
    TradeShows,
  },
  data: () => {
    return {
      loaded: false,
      saveSuccess: false,
      existingProfile: [],
      profile: {
        profileInfo: {
          companyname: "",
          companyurl: "",
          logoalt: "",
          uploadedImage: null,
          displaylogoalt: false,
          address1: "",
          address2: "",
          city: "",
          state: "",
          country: "",
          region: "",
          postalcode: "",
          phone: "",
          tollfree: "",
          fax: "",
        },
        socialMediaLinks: {
          facebookurl: "",
          twitterurl: "",
          linkedinurl: "",
          instagramurl: "",
          youtubeurl: "",
        },
        description: { description: "" },
      },
    };
  },
  methods: {
    canAccess(feature, plan) {
      return UpsellService.planIncludes(feature, plan);
    },
    async saveProfile() {
      let companyid = this.$auth.activeCompany.companyId;

      let ProfileDTO = {
        companyid: companyid,
        companyname: this.profile.profileInfo.companyname,
        companyurl: this.profile.profileInfo.companyurl,
        logoalt: this.profile.profileInfo.logoalt,
        address: this.profile.profileInfo.address1,
        address2: this.profile.profileInfo.address2,
        city: this.profile.profileInfo.city,
        state: this.profile.profileInfo.state,
        stateid: this.profile.profileInfo.stateid,
        postalcode: this.profile.profileInfo.postalcode,
        country: this.profile.profileInfo.country,
        countryid: this.profile.profileInfo.countryid,
        phone: this.profile.profileInfo.phone,
        tollfree: this.profile.profileInfo.tollfree,
        fax: this.profile.profileInfo.fax,
        description: this.profile.description.description,
        facebookurl: this.profile.socialMediaLinks.facebookurl,
        twitterurl: this.profile.socialMediaLinks.twitterurl,
        linkedinurl: this.profile.socialMediaLinks.linkedinurl,
        instagramurl: this.profile.socialMediaLinks.instagramurl,
        youtubeurl: this.profile.socialMediaLinks.youtubeurl,
      };

      if (
        this.profile.profileInfo.uploadedImage &&
        this.profile.profileInfo.uploadedImage.serverName
      ) {
        ProfileDTO.image = this.profile.profileInfo.uploadedImage.serverName;
      }

      TrackingService.track("update profile");

      try {
        let postResult = await ProfileService.saveProfile(ProfileDTO);
        await ProfileService.saveProfileImage(ProfileDTO);

        if (postResult.success) {
          this.saveSuccess = true;
          setTimeout(() => {
            this.saveSuccess = false;
          }, 5000);
          await this.getCompanyProfile();
        } else {
          this.saveSuccess = false;
        }
      } catch (e) {
        console.log(e);
        this.saveSuccess = false;
      }
    },
    async cancelEdit() {
      await this.getCompanyProfile();
    },
    async getCompanyProfile() {
      let company = this.$auth.activeCompany;

      let profileFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      let profileDTO = await ProfileService.getProfile(profileFilter);

      // set properties
      this.profile.profileInfo.companyname = profileDTO.companyName;
      this.profile.profileInfo.companyurl = profileDTO.companyUrl;
      this.profile.profileInfo.logoalt = profileDTO.logoAlt;

      // make logo alt check box selected if alt text exists
      if (this.profile.profileInfo.logoalt) {
        this.profile.profileInfo.displaylogoalt = true;
      }

      if (profileDTO.image && profileDTO.image !== "") {
        this.profile.profileInfo.uploadedImage = {
          clientName: profileDTO.image,
          serverName: profileDTO.image,
          baseUrl: profileDTO.baseUrl,
          sasToken: "",
        };
      }
      this.profile.profileInfo.address1 = profileDTO.address;
      this.profile.profileInfo.address2 = profileDTO.address2;
      this.profile.profileInfo.city = profileDTO.city;
      this.profile.profileInfo.state = profileDTO.state;
      this.profile.profileInfo.postalcode = profileDTO.postalCode;
      this.profile.profileInfo.country = profileDTO.country;
      this.profile.profileInfo.phone = profileDTO.phone;
      this.profile.profileInfo.tollfree = profileDTO.tollFree;
      this.profile.profileInfo.fax = profileDTO.fax;
      this.profile.description.description = profileDTO.description;
      this.profile.socialMediaLinks.facebookurl = profileDTO.faceBookUrl;
      this.profile.socialMediaLinks.twitterurl = profileDTO.twitterUrl;
      this.profile.socialMediaLinks.linkedinurl = profileDTO.linkedInUrl;
      this.profile.socialMediaLinks.instagramurl = profileDTO.instagramUrl;
      this.profile.socialMediaLinks.youtubeurl = profileDTO.youTubeUrl;

      //save profileDTO
      this.existingProfile = profileDTO;
    },
  },
  async mounted() {
    // load company profile data
    await this.getCompanyProfile();
    this.loaded = true;

    TrackingService.track("viewed company profile");
  },
};
</script>
