<template>
  <div class="card mb-3" v-if="loaded">
    <div class="card-body d-flex">
      <span class="me-auto">
        <a
          :href="`https://${fullSite.directoryurl}/company/${
            $auth.activeCompany.companyId
          }?cachebuster=${Math.round(Math.random() * 1000)}&cachepassthrough=1`"
          target="_blank"
          @click="trackListingClick"
        >
          {{ fullSite.sitename }}
        </a>
      </span>

      <span class="badge bg-blue-lt me-3">{{ listingType }}</span>
      <router-link :to="`/newupgrade?site=${fullSite.siteid}&showTop=1`">
        <span class="badge bg-green-lt" v-if="listingType === 'FREE'">
          Upgrade
        </span>
      </router-link>
    </div>
  </div>
</template>

<script>
import SiteService from "../../services/SiteService";
import TrackingService from "../../services/TrackingService";

export default {
  name: "profile.site-listing",
  props: {
    site: Object,
  },
  computed: {
    listingType() {
      if (!this.site.listingType) return;

      return this.site.listingType.toUpperCase();
    },
  },
  methods: {
    trackListingClick() {
      TrackingService.track("update profile", {
        siteId: this.site.siteID,
      });
    },
  },
  data() {
    return {
      fullSite: {},
      loaded: false,
    };
  },
  async mounted() {
    this.fullSite = await SiteService.getSite(this.site.siteID);
    this.loaded = true;
  },
};
</script>
