<template>
  <div
    class="mb-3 border rounded p-2"
    v-if="editable || Object.keys(matched).length > 0"
  >
    <h4>{{ groupName }}</h4>

    <div class="row" v-if="Object.keys(matched).length > 0 || adding">
      <div class="col-5">
        <label class="form-label">Parameter</label>
      </div>
      <div class="col-5">
        <label class="form-label">Value</label>
      </div>
    </div>

    <div
      class="mb-3 row"
      v-for="(match, idx) in matched"
      :key="`${uniqueId}-edit-grp-${idx}`"
    >
      <div class="col-5">
        <input
          type="text"
          :value="idx"
          disabled=""
          class="form-control disabled"
        />
      </div>
      <div class="col-5">
        <input
          type="text"
          :value="match"
          disabled=""
          class="form-control disabled"
        />
      </div>
      <div class="col-2">
        <button
          class="btn btn-outline btn-icon"
          @click="remove(idx)"
          v-if="editable"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon icon-tabler icon-tabler-x"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M18 6l-12 12"></path>
            <path d="M6 6l12 12"></path>
          </svg>
        </button>
      </div>
    </div>

    <div class="row" v-if="adding">
      <div class="col-5">
        <input
          type="text"
          v-model="add.parameter"
          class="form-control"
          v-if="matchParameters.length == 0"
        />
        <select v-model="add.parameter" class="form-select" v-else>
          <option
            v-for="(param, idx) in matchParameters"
            :key="`${uniqueId}-match-${idx}`"
            :value="param"
          >
            {{ param }}
          </option>
        </select>
      </div>
      <div class="col-5">
        <input type="text" v-model="add.value" class="form-control" />
      </div>
      <div class="col-2">
        <button class="btn btn-outline" @click="save">Add</button>
      </div>
    </div>

    <button
      v-if="editable && !adding"
      @click.prevent="adding = true"
      class="btn btn-outline"
    >
      Add Parameter
    </button>
  </div>
</template>

<script>
export default {
  name: "forms.advanced-url.parameter-group",
  props: {
    uniqueId: String,
    groupName: String,
    matchParameters: Array,
    excludeParameters: Array,
    editable: Boolean,
    value: Object,
  },
  data() {
    return {
      adding: false,
      add: {
        parameter: null,
        value: null,
      },
    };
  },
  methods: {
    edit(param, value) {
      // currently unused, not the best way to edit but simple
      this.remove(param);
      this.add.parameter = param;
      this.add.value = value;
      this.adding = true;
    },
    remove(param) {
      let oldParams = {};
      Object.keys(this.value).forEach((k) => {
        if (param !== k) {
          oldParams[k] = this.value[k];
        }
      });
      this.$emit("input", oldParams);
    },
    save() {
      let oldParams = {};
      Object.keys(this.value).forEach((k) => {
        oldParams[k] = this.value[k];
      });
      oldParams[this.add.parameter] = this.add.value;

      this.$emit("input", oldParams);

      this.adding = false;
      this.add.parameter = null;
      this.add.value = null;
    },
  },
  computed: {
    matched() {
      let params = this.matchParameters;

      if (!params || this.matchParameters.length == 0) {
        if (this.excludeParameters) {
          let notMatching = {};
          Object.keys(this.value).forEach((param) =>
            this.excludeParameters.includes(param)
              ? null
              : (notMatching[param] = this.value[param])
          );
          return notMatching;
        } else {
          return {};
        }
      }

      let matching = {};

      params.forEach((param) =>
        this.value[param] ? (matching[param] = this.value[param]) : null
      );

      return matching;
    },
  },
};
</script>
