<template>
  <b-modal
    size="sm"
    :id="`${uniqueId}-advanced-url-modal`"
    :hide-header="true"
    :hide-footer="true"
  >
    <template #default="{ close }">
      <div class="modal-status bg-info"></div>

      <button type="button" class="btn-close" @click.prevent="close()"></button>

      <h3>Edit URL</h3>

      <div class="mb-3">
        <label class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            :checked="scheme == 'https:'"
            @change="
              scheme == 'https:' ? (scheme = 'http:') : (scheme = 'https:')
            "
          />
          <span class="form-check-label">Secure URL (HTTPS)</span>
        </label>
      </div>

      <div class="mb-3">
        <label class="form-label" :for="`${uniqueId}-advanced-modal-host`">
          Base URL
        </label>
        <input
          type="text"
          class="form-control"
          v-model="host"
          :id="`${uniqueId}-advanced-modal-host`"
        />
      </div>

      <div class="mb-3">
        <label class="form-label" :for="`${uniqueId}-advanced-modal-path`">
          Path
        </label>
        <input
          type="text"
          class="form-control"
          v-model="path"
          :id="`${uniqueId}-advanced-modal-path`"
        />
      </div>

      <parameter-group
        :groupName="`Marketing Parameters`"
        :uniqueId="`${uniqueId}-marketing-params`"
        :editable="true"
        :matchParameters="[
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_term',
          'utm_content',
        ]"
        v-model="parameters"
      ></parameter-group>

      <parameter-group
        :groupName="`Custom Parameters`"
        :uniqueId="`${uniqueId}-custom-params`"
        :matchParameters="[]"
        :excludeParameters="[
          'utm_source',
          'utm_medium',
          'utm_campaign',
          'utm_term',
          'utm_content',
          'ntls',
        ]"
        :editable="true"
        v-model="parameters"
      ></parameter-group>

      <parameter-group
        :groupName="`System Parameters`"
        :uniqueId="`${uniqueId}-system-params`"
        :editable="false"
        :matchParameters="['ntls']"
        v-model="parameters"
      ></parameter-group>

      <div class="mb-3">
        <label class="form-label">
          Final URL (
          <a :href="domain" target="_blank">Open preview in a new tab</a>
          )
        </label>
        <input
          type="text"
          disabled
          :value="domain"
          class="form-control disabled"
        />
      </div>

      <div class="mb-3">
        <button class="btn btn-primary" @click="close">Close Editor</button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ParameterGroup from "./ParameterGroup.vue";
export default {
  components: { ParameterGroup },
  name: "forms.advanced-url.advanced-url-modal",
  props: {
    value: String,
    uniqueId: String,
  },
  data() {
    return {
      domain: this.value,
      scheme: "",
      host: "",
      path: "",
      parameters: {},
    };
  },
  watch: {
    value(updated) {
      if (updated != this.domain) {
        console.log("reinitialize change");
        this.initDomain();
      }
    },
    path() {
      this.buildDomain();
    },
    host() {
      if (this.host.startsWith("http://")) {
        this.scheme = "http:";
        this.host = this.host.replace("http://", "");
      } else if (this.host.startsWith("https://")) {
        this.scheme = "https:";
        this.host = this.host.replace("https://", "");
      }

      this.buildDomain();
    },
    scheme() {
      if (this.scheme == "http:") {
        this.$set(this.parameters, "ntls", 1);
      } else {
        if (this.parameters["ntls"]) {
          this.$delete(this.parameters, "ntls");
        }
      }
      this.buildDomain();
    },
    parameters() {
      this.buildDomain();
    },
    domain() {
      this.$emit("input", this.domain);
    },
  },
  methods: {
    initDomain() {
      this.domain = this.value;
      let parsedDomain = this.parseDomain(this.domain);
      if (!parsedDomain) {
        return;
      }

      this.host = parsedDomain.hostname;
      this.scheme = parsedDomain.protocol;
      this.path = parsedDomain.pathname;
      parsedDomain.searchParams.forEach((val, ke) => {
        this.parameters[ke] = val;
      });
    },
    buildDomain() {
      let url = this.parseDomain(this.domain);
      url.protocol = this.scheme;
      url.hostname = this.host;
      url.pathname = this.path;

      let searchParams = new URLSearchParams();
      Object.keys(this.parameters).forEach((searchKey) => {
        searchParams.append(searchKey, this.parameters[searchKey]);
      });

      url.search = searchParams.toString();

      this.domain = url.toString();
    },
    parseDomain(domain) {
      try {
        return new URL(domain);
      } catch (e) {
        return null;
      }
    },
  },
  mounted() {
    this.initDomain();
  },
};
</script>
