<template>
  <div class="card card-md">
    <div class="card-body">
      <div class="row" v-if="saveSuccess">
        <div class="col-12">
          <div class="alert alert-success">Information saved successfully.</div>
        </div>
      </div>
      <div class="row">
        <p class="h2 pb-1">
          <strong>{{ $auth.activeCompany.companyName }}</strong>
        </p>
        <p class="h5 pb-3">(<strong>Bold</strong> fields are required)</p>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label class="form-label"> Company Logo:</label>
          <drag-and-drop-upload-card
            v-model="value.uploadedImage"
            :label="`company logo`"
            :helpText="`Logo will be resized to fit 120px high by 240px wide.`"
            :contentTypes="['png', 'jpg', 'jpeg', 'gif', 'svg']"
            :inlinePreview="true"
            :uniqueId="`content-file`"
            :uploadTo="`images`"
            :resizeImage="true"
            :maxHeight="60 * 3"
            :maxWidth="120 * 3"
            :displayWidth="120"
            :displayHeight="60"
            v-if="canAccess('logo', $auth.highestPlan)"
          ></drag-and-drop-upload-card>
          <div
            class="pt-1 pb-0 mb-2 checkboxes"
            v-if="canAccess('logo', $auth.highestPlan) && value.uploadedImage"
          >
            <b-form-checkbox
              label="Customize image alt text"
              id="customizeimage"
              v-model="value.displaylogoalt"
              :value="true"
              :unchecked-value="false"
            >
              <span class="d-inline logoalt form-label">
                Customize image alt text
              </span>
            </b-form-checkbox>
            <div class="mb-3" v-if="value.displaylogoalt">
              <label class="form-label mt-3">Alt Text:</label>
              <input
                class="form-control"
                id="logoalt"
                ref="logoalt"
                v-model="value.logoalt"
              />
            </div>
          </div>
          <upgrade-box v-else>
            <p>
              <strong>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-alert-triangle text-red"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 9v2m0 4v.01"></path>
                  <path
                    d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"
                  ></path>
                </svg>
                Your listing doesn't feature your company's logo.
              </strong>
            </p>
          </upgrade-box>
        </div>
        <div class="col-md-6">
          <listings></listings>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label font-weight-bold"> Company Name: </label>
            <input
              :class="[
                'form-control',
                $v.value.companyname.$error ? 'is-invalid' : '',
              ]"
              id="validateCompanyName"
              ref="companyname"
              v-model="$v.value.companyname.$model"
              required
            />
            <div class="invalid-feedback" v-if="!$v.value.companyname.required">
              <small>Please enter a company name</small>
            </div>
          </div>
          <div class="mb-3">
            <label
              class="form-label mt-1 font-weight-bold"
              for="company-profile-url"
            >
              Website:
            </label>

            <advanced-url
              :uid="`company-profile-url`"
              :invalid="$v.value.companyurl.$error"
              :feedback="`Please enter a website URL.`"
              :required="true"
              v-model="$v.value.companyurl.$model"
            ></advanced-url>
          </div>
          <div class="mb-3">
            <label class="form-label mt-3 font-weight-bold">Phone:</label>
            <input
              :class="[
                'form-control',
                $v.value.phone.$error ? 'is-invalid' : '',
              ]"
              id="validatePhone"
              ref="phone"
              v-model="$v.value.phone.$model"
              required
            />
            <div class="invalid-feedback" v-if="!$v.value.phone.required">
              <small>Please enter a phone number</small>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label mt-3">Toll-free:</label>
            <input class="form-control" v-model="value.tollfree" />
          </div>
          <div class="mb-3">
            <label class="form-label mt-3">Fax:</label>
            <input class="form-control" v-model="value.fax" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label class="form-label font-weight-bold">
              Address (line 1):
            </label>
            <input
              :class="[
                'form-control',
                $v.value.address1.$error ? 'is-invalid' : '',
              ]"
              id="validateAddress1"
              ref="address1"
              v-model="$v.value.address1.$model"
              required
            />
            <div class="invalid-feedback" v-if="!$v.value.address1.required">
              <small>Please enter a valid address</small>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label mt-1">Address (line 2):</label>
            <input class="form-control" v-model="value.address2" />
          </div>

          <div class="mb-3">
            <label class="form-label font-weight-bold"> Country:</label>
            <select
              :class="[
                'form-control form-select',
                $v.value.country.$error ? 'is-invalid' : '',
              ]"
              id="validateCountry"
              ref="country"
              v-model="$v.value.country.$model"
              required
              @change="updateSelectedCountry($event.target.value)"
            >
              <option v-bind:value="-1">Select</option>
              <option v-for="country in countryDTOs" :key="country.countryid">
                {{ country.country }}
              </option>
            </select>
            <div class="invalid-feedback" v-if="!$v.value.country.required">
              <small>Please select a country</small>
            </div>
          </div>
          <div class="mb-3 row">
            <div class="col-md-4">
              <label class="form-label font-weight-bold">City:</label>
              <input
                :class="[
                  'form-control',
                  $v.value.city.$error ? 'is-invalid' : '',
                ]"
                id="validateCity"
                ref="city"
                v-model="$v.value.city.$model"
                required
              />
              <div class="invalid-feedback" v-if="!$v.value.city.required">
                <small>Please enter a city</small>
              </div>
            </div>
            <div class="col-md-4" v-if="showState">
              <label class="form-label font-weight-bold">
                {{ statelabel }}:
              </label>
              <select
                :class="['form-control form-select']"
                id="validateState"
                ref="state"
                v-model="value.state"
                required
              >
                <option v-bind:value="-1">Select</option>
                <option v-for="state in stateDTOs" :key="state.stateid">
                  {{ state.state }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <label class="form-label font-weight-bold">Postal Code:</label>
              <input
                :class="[
                  'form-control',
                  $v.value.postalcode.$error ? 'is-invalid' : '',
                ]"
                id="validatePostalCode"
                ref="postalcode"
                v-model="$v.value.postalcode.$model"
                required
              />
              <div
                class="invalid-feedback"
                v-if="!$v.value.postalcode.required"
              >
                <small>Please enter a postalcode</small>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-3" v-if="showRegion">
            <label class="form-label font-weight-bold">Region:</label>
            <input class="form-control" v-model="value.region" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <save-button
            @save="ProfileInfo"
            @cancel="$emit('cancel')"
          ></save-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import UpsellService from "../../services/UpsellService";
import ProfileService from "../../services/ProfileService";
import DragAndDropUploadCard from "../Content/Upload/DragAndDropUploadCard.vue";
import Listings from "./Listings.vue";
import UpgradeBox from "../Upgrade/UpgradeBox.vue";
import SaveButton from "./SaveButton.vue";
import AdvancedUrl from "../Forms/AdvancedUrl.vue";

export default {
  name: "profile-info",
  components: {
    DragAndDropUploadCard,
    Listings,
    UpgradeBox,
    SaveButton,
    AdvancedUrl,
  },
  props: {
    value: Object,
    saveSuccess: Boolean,
  },
  data: () => {
    return {
      stateDTOs: [],
      existingStates: [],
      existingCanadianProvinces: [],
      countryDTOs: [],
      canadianProvincesDTOs: [],
    };
  },
  validations: {
    value: {
      companyname: {
        required,
      },
      companyurl: {
        required,
      },
      address1: {
        required,
      },
      city: {
        required,
      },
      country: {
        required,
      },
      postalcode: {
        required,
      },
      phone: {
        required,
      },
    },
  },
  methods: {
    canAccess(feature, plan) {
      return UpsellService.planIncludes(feature, plan);
    },
    ProfileInfo() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.$emit("save");
      }
    },
    async getCountries() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.countryDTOs = await ProfileService.getCountries(companyFilter);
    },
    async getStates() {
      let company = this.$auth.activeCompany;

      let companyFilter = {
        Company: { Companyid: company.companyId },
      };

      // make service call
      this.stateDTOs = await ProfileService.getStates(companyFilter);
      this.existingStates = this.stateDTOs;

      this.canadianProvincesDTOs = await ProfileService.getCanadianProvinces(
        companyFilter
      );
      this.existingCanadianProvinces = this.canadianProvincesDTOs;

      await this.updateSelectedCountry(this.value.country);
    },
    async updateSelectedCountry(country) {
      if (country == "Canada") {
        this.stateDTOs = this.existingCanadianProvinces;
      } else if (country == "United States") {
        this.stateDTOs = this.existingStates;
      }
    },
  },
  computed: {
    statelabel() {
      if (this.value.country == "United States") {
        return "State";
      } else {
        return "Province";
      }
    },
    showRegion() {
      return (
        this.value.country.length > 0 &&
        this.value.country != "United States" &&
        this.value.country != "Canada"
      );
    },
    showState() {
      return (
        // show or hide state
        this.value.country.length > 0 &&
        (this.value.country == "United States" ||
          this.value.country == "Canada")
      );
    },
  },
  async mounted() {
    await this.getCountries();
    // get states
    await this.getStates();
  },
};
</script>
